<template>
  <div v-if="event">
    <v-row class="mx-3"> 
      <v-col cols="12" sm="6" md="4">
        <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Join%20challenge%20-%20CorporateFitness.app.pdf">
          <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Join a Challenge</h3>
          <p>How to join a (step) challenge using our app.</p>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Connect%20Garmin%20-%20CorporateFitness.app.pdf">
          <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Connect with Garmin</h3>
          <p>How to connect with Garmin.</p>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" sm="6" md="4">
        <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Connect%20Strava%20-%20CorporateFitness.app.pdf">
          <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Connect with Strava</h3>
          <p>How to connect with Strava.</p>
        </v-card>
      </v-col> -->
      <v-col cols="12" sm="6" md="4">
        <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Connect%20Apple%20Watch%20-%20CorporateFitness.app.pdf">
          <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Connect with Apple Watch</h3>
          <p>How to connect your Apple Watch.</p>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Connect%20Fitbit%20-%20CorporateFitness.app.pdf">
          <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Connect with Fitbit</h3>
          <p>How to connect your Fitbit.</p>
        </v-card>
      </v-col>
      <v-col v-if="event.activity_mode !== 'ACTIVITY_BASED'" cols="12" sm="6" md="4">
        <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Enable%20Step%20Tracking%20-%20CorporateFitness.app.pdf">
          <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Enable Step Tracking</h3>
          <p>How to enable step tracking in the app.</p>
        </v-card>
      </v-col>
      <v-col v-if="event.allow_manual_entry" cols="12" sm="6" md="4">
        <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20QuickEntry%20-%20CorporateFitness.app.pdf">
          <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Add a Manual Entry</h3>
          <p>How to add a manual entry.</p>
        </v-card>
      </v-col>
    </v-row>         
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "EventTutorials",
  props: {
    event: Object,
  },
  data: function() {
    return {
    };
  },
  created() {
  },
  async mounted() {
    EventBus.$on('login-state-change', async user => {
    });
  },
  methods: {
    
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
};
</script>

<style scoped lang="scss">
</style>